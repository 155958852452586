import React, { useState, useEffect, createContext } from "react"
import Client from "shopify-buy"

const client = Client.buildClient({
  domain: process.env.GATSBY_MYSHOPIFY_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
})

const defaultState = {
  cart: {},
}

const CartContext = createContext(defaultState)
export default CartContext

export const CartContextProvider = ({ children }) => {
  const [showCart, setShowCart] = useState(false)
  const [cartItemCount, setCartItemCount] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== "undefined" ? localStorage.getItem("checkout") : null
    )
  )

  const [successfulOrder, setSuccessfulOrder] = useState(null)
  const checkoutId = checkout?.id

  useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== "undefined") {
        const fetchedCheckout = await client.checkout.fetch(checkoutId)

        setCheckout(fetchedCheckout)
        localStorage.setItem("checkout", JSON.stringify(fetchedCheckout))
      }
    }

    getCheckout()
  }, [setCheckout, setSuccessfulOrder, checkoutId])

  const getProductById = async (productId) => {
    const product = await client.product.fetch(productId)
    return product
  }

  const updateLineItem = async ({ variantId, quantity }) => {
    // if no checkout id, create a new checkout
    setIsWorking(true)
    let newCheckout = checkout || (await client.checkout.create())

    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.lineItems?.find(
      (lineItem) => lineItem.variant.id === variantId
    )

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity

      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ])
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [
          lineItemVariant.id,
        ])
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId,
          quantity,
        },
      ])
    }

    setCheckout(newCheckout)
    setSuccessfulOrder(null)
    if (typeof window !== "undefined") {
      localStorage.setItem("checkout", JSON.stringify(newCheckout))
    }
    setIsWorking(false)
  }

  const removeLineItem = async (lineItemId) => {
    setIsWorking(true)
    const newCheckout = await client.checkout.removeLineItems(checkoutId, [
      lineItemId,
    ])

    setCheckout(newCheckout)
    setIsWorking(false)
  }

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null)
  }

  return (
    <CartContext.Provider
      value={{
        checkout,
        updateLineItem,
        removeLineItem,
        getProductById,
        successfulOrder,
        dismissSuccessfulOrder,
        showCart,
        setShowCart,
        cartItemCount,
        setCartItemCount,
        isWorking,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
